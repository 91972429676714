import { render, staticRenderFns } from "./default.vue?vue&type=template&id=64e6a726&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterPanel: require('E:/Jenkins/workspace/PowerBi-Dashboard visfederatie (WEcR)/Presentatie/src/components/FilterPanel.vue').default,MobileLayout: require('E:/Jenkins/workspace/PowerBi-Dashboard visfederatie (WEcR)/Presentatie/src/components/mobile/MobileLayout.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol})
