






















import { Component, Vue } from 'nuxt-property-decorator'
import DefaultTemplate from '@template/components/defaultTemplate.vue'
import SmallMenu from '@template/components/SmallMenu.vue'
import { IMenuItem, MenuItem } from '@template/classes/menu'
import { getModule } from 'vuex-module-decorators'
import DefaultMenuUser from '@template/components/defaultMenuUser.vue'
import { Report } from 'powerbi-client'
import LoginModule from '~/store/LoginModule'
import ReportModule from '~/store/ReportModule'
import FilterPanel from '~/components/FilterPanel.vue'
import MobileLayout from '~/components/mobile/MobileLayout.vue'
import PowerBiModule from '~/store/PowerBiModule'

export type UserInfo = { name: string; email: string }

const isDesktopQuery = window.matchMedia('(min-width: 1024px)')

@Component({
  components: {
    DefaultMenuUser,
    DefaultTemplate,
    FilterPanel,
    MobileLayout,
    SmallMenu,
  },
})
export default class Default extends Vue {
  /* Props */
  public report: Report = {} as Report
  /* Watch */

  /* Data */
  isDesktop: boolean = isDesktopQuery.matches
  loginStore: LoginModule = getModule(LoginModule)
  reportStore: ReportModule = getModule(ReportModule)
  powerbiStore: PowerBiModule = getModule(PowerBiModule)

  menuItems: IMenuItem[] = []

  /* Enums */

  /* Lifecycle */
  async created() {
    await this.reportStore.getReportsAndPages()
    const reports = this.reportStore.reports

    this.handleViewportChange()
    this.addEventListeners()

    reports.forEach((report) => {
      this.menuItems.push(
        new MenuItem({
          title: 'Home',
          subTitle: report.description,
          disabled: false,
          icon: 'mdi-google-circles-communities',
          location: {
            name: 'index-introduction',
          },
        })
      )
      report.pages.forEach((page) => {
        this.menuItems.push(
          new MenuItem({
            title: page.name,
            icon: page.icon,
            color: 'primary',
            location: {
              name: 'index-page',
              params: {
                page: page.name,
              },
            },
            // onClick: () => this.SetPageOnReport(page.name),
          })
        )
      })
    })
    this.menuItems.push(
      new MenuItem({
        title: 'Disclaimer',
        icon: 'mdi-hand-back-left',
        color: 'primary',
        location: {
          name: 'index-disclaimer',
        },
      })
    )
    this.menuItems.push(
      new MenuItem({
        title: 'Contact',
        icon: 'mdi-handshake',
        color: 'primary',
        location: {
          name: 'index-contact',
        },
      })
    )
  }

  beforeDestroy() {
    this.removeEventListeners()
  }

  /* Computed */

  /* Methods */
  addEventListeners() {
    isDesktopQuery.addEventListener('change', this.handleDesktopChange)
    addEventListener('resize', this.handleViewportChange)
    addEventListener('orientationchange', this.handleViewportChange)
  }

  removeEventListeners() {
    isDesktopQuery.removeEventListener('change', this.handleDesktopChange)
    removeEventListener('resize', this.handleViewportChange)
    removeEventListener('orientationchange', this.handleViewportChange)
  }

  handleDesktopChange(event: MediaQueryListEvent) {
    this.isDesktop = event.matches
  }

  handleViewportChange() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }

  public async SetPageOnReport(pageName: string) {
    this.report = this.powerbiStore.getPBIReport
    const pages = await this.report.getPages()
    const selectedPage = pages.filter((p) => p.displayName === pageName)
    await selectedPage[0].setActive()
  }

  get isLoggedIn(): boolean {
    return !!this.loginStore.token.length
  }
}
