export class ApiUrl {
  /**
   * ApiUrl() gives the endpoint for the web api
   */
  public static webapiurl() {
    switch (window.location.host) {
      case 'visfederatie.wecr.wur.nl':
        return 'https://visfederatie.wecr.wur.nl/WebApi'
      case 'acc.visfederatie.wecr.wur.nl':
        return 'https://acc.visfederatie.wecr.wur.nl/WebApi'
      case 'tst.visfederatie.wecr.wur.nl':
        return 'https://tst.visfederatie.wecr.wur.nl/WebApi'
      default:
        return 'http://localhost:50256'
    }
  }
}
